<template>
    <div>   
        <div class="row">
			<div class="col-md-12 mb-4">
				<div class="card shadow border-0 round">
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Bantuan</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/panduan-belanja">Panduan Belanja</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/pembayaran">Pembayaran</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/pengembalian">Pengembalian</router-link></li>
					</ul>
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Informasi</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/pengiriman">Pengiriman</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/privacy-policy">Privacy Policy</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/syarat-ketentuan">Syarat dan Ketentuan</router-link></li>
					</ul>
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Karir</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/karir">Karir</router-link></li>
					</ul>
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Promo</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/promo">Promo</router-link></li>
					</ul>
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Hubungi Kami</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/call-center">Call center</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/customer-service">Customer Service</router-link></li>
					</ul>
					<div class="title text-white "><h6 class="text-left ml-4 pl-3 mb-0"><i class="fa fa-align-left fa-lg mr-3"></i><strong>Tentang Kami</strong></h6></div>
					<ul class="list-group list-group-flush mx-3">
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/tentang-kami">Tentang Kami</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/kelebihan-kami">Kelebihan Kami</router-link></li>
						<li class="list-group-item"><router-link class="text-decoration-none product-category" to="/static/website">Website</router-link></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	name: 'SidebarPanduan',
}

</script>