<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">PrivacyPolicy</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Privacy Policy</h2>
						</div>
						<div class="text-left">
							<h6>KEBIJAKAN PRIVASI SITUS DAN APLIKASI Dunia Murah </h6>
							<p>Dunia Murah memahami dan menghormati privasi Anda dan nilai hubungan kami dengan Anda. Kebijakan Privasi ini menjelaskan bagaimana Dunia Murah mengumpulkan, mengatur dan melindungi informasi Anda ketika Anda mengunjungi dan/atau menggunakan situs atau aplikasi Dunia Murah, bagaimana Dunia Murah menggunakan informasi dan kepada siapa Dunia Murah dapat berbagi. Kebijakan privasi ini juga memberitahu Anda bagaimana Anda dapat meminta Dunia Murah untuk mengakses atau mengubah informasi Anda serta menjawab pertanyaan Anda sehubungan dengan Kebijakan Privasi ini.</p>
							<p>Kata-kata yang dimulai dengan huruf besar dalam Kebijakan Privacy ini mempunyai pengertian yang sama dengan Syarat dan Ketentuan penggunaan situs dan aplikasi Dunia Murah.</p>
							<h6>Informasi yang kami kumpulkan</h6>
							<p>Dunia Murah dapat memperoleh dan mengumpulkan informasi dan/atau konten dari situs dan aplikasi yang Anda atau pengguna lain sambungkan atau disambungkan oleh situs atau aplikasi Dunia Murah dengan situs atau pengguna tertentu dan informasi dan/atau konten yang Anda berikan melalui penggunaan situs atau aplikasi Dunia Murah dan/atau pengisian Aplikasi.</p>
							<p>Ketika Anda mengunjungi situs atau aplikasi Dunia Murah, Dunia Murah dapat mengumpulkan informasi apapun yang telah dipilih bisa terlihat oleh semua orang dan setiap informasi publik yang tersedia. Informasi ini dapat mencakup nama Anda, gambar profil, jenis kelamin, kota saat ini, hari lahir, email, jaringan, daftar teman, dan informasi-informasi Anda lainnya yang tersedia dalam jaringan. Selain itu, ketika Anda menggunakan aplikasi Dunia Murah, atau berinteraksi dengan alat terkait, widget atau plug-in, Dunia Murah dapat mengumpulkan informasi tertentu dengan cara otomatis, seperti cookies dan web beacon. Informasi yang Dunia Murah kumpulkan dengan cara ini termasuk alamat IP, perangkat pengenal unik, karakteristik perambah, karakteristik perangkat, sistem operasi, preferensi bahasa, URL, informasi tentang tindakan yang dilakukan, tanggal dan waktu kegiatan. Melalui metode pengumpulan otomatis ini, Dunia Murah mendapatkan informasi mengenai Anda. Dunia Murah mungkin menghubungkan unsur-unsur tertentu atas data yang telah dikumpulkan melalui sarana otomatis, seperti informasi browser Anda, dengan informasi lain yang diperoleh tentang Anda, misalnya, apakah Anda telah membuka email yang dikirimkan kepada Anda. Dunia Murah juga dapat menggunakan alat analisis pihak ketiga yang mengumpulkan informasi tentang lalu lintas pengunjung situs atau aplikasi Dunia Murah. Browser Anda mungkin memberitahu Anda ketika Anda menerima cookie jenis tertentu atau cara untuk membatasi atau menonaktifkan beberapa jenis cookies. Harap dicatat, bahwa tanpa cookie Anda mungkin tidak dapat menggunakan semua fitur dari situs atau aplikasi Dunia Murah.</p>
							<p>Situs atau aplikasi Dunia Murah mungkin berisi link ke tempat pihak lain yang dapat dioperasikan oleh pihak lain tersebut yang mungkin tidak memiliki kebijakan privasi yang sama dengan Dunia Murah. Dunia Murah sangat menyarankan Anda untuk membaca dan mempelajari kebijakan privasi dan ketentuan-ketentuan pihak lain tersebut sebelum masuk atau menggunakannya. Dunia Murah tidak bertanggung jawab atas pengumpulan dan/atau penyebaran informasi pribadi Anda oleh pihak lain atau yang berkaitan dengan penggunaan media sosial seperti Facebook dan Twitter dan Dunia Murah dibebaskan dari segala akibat yang timbul atas penyebaran dan/atau penyalahgunaan informasi tersebut.</p>
							<h6>BAGAIMANA Dunia Murah MENGGUNAKAN INFORMASI</h6>
							<p>Dunia Murah dapat menggunakan informasi Anda yang diperoleh untuk menyediakan produk dan layanan yang Anda minta, sebagai data riset atau berkomunikasi tentang dan/atau mengelola partisipasi Anda dalam survei atau undian atau kontes atau acara khusus lainnya yang diadakan oleh Dunia Murah, pengoperasian Dunia Murah, memberikan dukungan kepada Anda sebagai pengunjung dan/atau pengguna situs atau aplikasi Dunia Murah, merespon dan berkomunikasi dengan Anda mengenai permintaan Anda, pertanyaan dan/atau komentar Anda, membiarkan Anda untuk meninggalkan komentar di situs atau aplikasi Dunia Murah atau melalui media sosial lainnya, membangun dan mengelola Akun Anda, mengirimkan berita-berita dan/atau penawaran-penawaran yang berlaku bagi Anda selaku pengunjung dan penguna situs atau aplikasi Dunia Murah, untuk mengoperasikan, mengevaluasi dan meningkatkan bisnis Dunia Murah termasuk untuk mengembangkan produk dan layanan baru; untuk mengelola komunikasi Dunia Murah, menentukan efektifitas layanan, pemasaran dan periklanan situs atau aplikasi Dunia Murah, dan melakukan akutansi, audit, dan kegiatan Dunia Murah lainnya, melakukan analisis data termasuk pasar dan pencarian konsumen, analisis trend, keuangan, dan informasi pribadi, melaksanakan kerjasama dengan mitra Dunia Murah yang terkait dengan program-program yang diadakan oleh Dunia Murah, melindungi, mengidentifikasi, dan mencegah penipuan dan kegiatan kriminal lainnya, klaim dan kewajiban lainnya, membantu mendiagnosa masalah teknis dan layanan, untuk memelihara, mengoperasikan, atau mengelola situs atau aplikasi Dunia Murah yang dilakukan oleh Dunia Murah atau pihak lain yang ditentukan oleh Dunia Murah, mengidentifikasi pengguna situs atau aplikasi Dunia Murah, serta mengumpulkan informasi demografis tentang pengguna situs atau aplikasi Dunia Murah, untuk cara lain yang Dunia Murah beritahukan pada saat pengumpulan informasi.</p>
							<p>Dunia Murah tidak akan menjual atau memberikan informasi pribadi Anda kepada pihak lain, kecuali seperti yang dijelaskan dalam kebijakan privasi ini. Dunia Murah akan berbagi informasi dengan afiliasi Dunia Murah atau pihak lain yang melakukan layanan berdasarkan petunjuk dari Dunia Murah. Pihak lain tersebut tidak diizinkan untuk menggunakan atau mengungkapkan informasi tersebut kecuali diperlukan untuk melakukan layanan atas nama Dunia Murah atau untuk mematuhi persyaratan hukum. Dunia Murah juga dapat berbagi informasi dengan pihak lain yang merupakan mitra Dunia Murah untuk menawarkan produk atau jasa yang mungkin menarik bagi Anda.</p>
							<p>Dunia Murah dapat mengungkapkan informasi jika dianggap perlu dalam kebijakan tunggal Dunia Murah, untuk mematuhi hukum yang berlaku, peraturan, proses hukum atau permintaan pemerintah, dan peraturan yang berlaku di Dunia Murah. Selain itu, Dunia Murah dapat mengungkapkan informasi ketika percaya, pengungkapan diperlukan atau wajib dilakukan untuk mencegah kerusakan fisik atau kerugian finansial atau hal lainnya sehubungan dengan dugaan atau terjadinya kegiatan ilegal. Dunia Murah juga berhak untuk mengungkapkan dan/atau mengalihkan informasi yang dimiliki apabila sebagian atau seluruh bisnis atau aset Dunia Murah dijual atau dialihkan.</p>
							<p>Dunia Murah dapat menyimpan dan/atau memusnahkan informasi tentang Anda sesuai kebijakan yang berlaku atau jika diperlukan.</p>
							<h6>UPDATE KEBIJAKAN PRIVASI INI</h6>
							<p>Kebijakan Privasi ini mungkin diperbarui secara berkala dan tanpa pemberitahuan sebelumnya kepada Anda untuk mencerminkan perubahan dalam praktik informasi pribadi. Dunia Murah akan menampilkan pemberitahuan di bagian info profil website untuk memberitahu Anda tentang perubahan terhadap Kebijakan Privasi dan menunjukkan di bagian atas Kebijakan saat ketika Kebijakan Privasi ini terakhir diperbarui. Kebijakan Privasi ini merupakan satu kesatuan dan menjadi bagian yang tidak terpisahkan dari Syarat dan Ketentuan Penggunaan situs dan aplikasi Dunia Murah.</p>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>